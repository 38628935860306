.metrics-table {
  display: block;
  margin-top: 24px;
  max-width: 100%;
  font-family: var(--font-primary);
  text-align: center;

  p {
    margin-bottom: 10px;
    font-size: 1.125rem;
    font-weight: bold;
    text-align: left;
  }

  .table-wrap {
    display: block;
    max-width: 100%;
    overflow-y: hidden;
    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-radius: 4px;

    table {
      width: 100%;
      border-spacing: 0;
      background-color: var(--bg-secondary);

      tr {
        height: 40px;

        &:hover {
          background: var(--bg-primary);
        }
      }

      th {
        border-color: var(--border-color);
        background-color: var(--blue-secondary);
        padding: 10px;
        font-size: 0.75rem;
      }

      td {
        padding: 9px;
        color: var(--text-primary);
        font-size: 0.875rem;
      }

      th,
      td {
        margin: 0;
        border-bottom: 1px solid var(--border-color);

        width: 1%;
        white-space: nowrap;
      }

      .externalLink {
        margin-left: 4px;
        border-radius: 1px;
        padding-top: 1px;
        padding-right: 3px;
        padding-left: 4px;
        outline: none;

        &:focus {
          box-shadow: 0 0 0 2px var(--blue-primary);
        }

        svg {
          fill: var(--blue-primary);
        }
      }
    }
  }

  .pagination {
    display: block;
    font-size: 0.875rem;

    button {
      margin: 18px 6px 12px;
      font-size: 1rem;
      color: var(--blue-primary);
      background-color: var(--blue-secondary);
      border: 1px solid var(--border-color);
      border-radius: 4px;
      outline: none;

      @media (min-width: 768px) {
        width: 40px;
        height: 40px;
      }

      &:disabled {
        color: var(--text-secondary);
      }

      &:focus {
        box-shadow: 0 0 0 2px var(--blue-primary);
      }
    }
  }
}
