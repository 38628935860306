.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);

  .auth {
    flex: 1;
    max-width: 520px;
    margin: 32px 16px;
    padding: 16px 32px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--bg-secondary);
    font-family: var(--font-primary);

    .background {
      float: right;
      margin-top: 18px;

      @media (max-width: 399px) {
        display: none;
      }
    }
  }
}
