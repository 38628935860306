.no-data {
  margin-top: 120px;
  text-align: center;
  color: var(--text-secondary);

  p {
    font-size: 1.375rem;

    @media (max-width: 799px) {
      font-size: 1.125rem;
    }

    @media (max-width: 519px) {
      font-size: 0.875rem;
    }
  }
}
