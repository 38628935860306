.section-title {
  margin-bottom: 6px;
  text-align: left;
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: bold;

  &:not(:first-of-type) {
    margin-top: 32px;
  }
}
