.side-drawer {
  position: fixed;
  width: 220px;
  max-width: 45%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: var(--bg-secondary);
  padding: 60px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;

  @media (min-width: 920px) {
    display: none;
  }

  .logo-side-drawer {
    display: none;
    height: 60px;
  }

  .navigation-item a {
    font-size: 0.875rem;
  }
}

.open {
  transform: translateX(0);
}

.close {
  transform: translate(-150%);
}
