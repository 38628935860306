.documentation-container {
  font-family: var(--font-secondary);

  img {
    filter: var(--invert-color);
  }

  h1,
  h2,
  h3,
  h4 {
    color: var(--blue-primary);
    margin-bottom: 16px;
    font-weight: bold;
    scroll-margin-top: 65px;
  }

  h2 {
    margin-top: 28px;
    font-size: 1.75rem;

    @media (max-width: 699px) {
      font-size: 1.25rem;
    }
  }

  h3 {
    font-size: 1.25rem;

    @media (max-width: 699px) {
      font-size: 1.125rem;
    }
  }

  h4 {
    font-size: 1.125rem;

    @media (max-width: 699px) {
      font-size: 1rem;
    }
  }

  li {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    padding-left: 26px;
  }

  ul {
    list-style-type: disc;
  }

  table {
    margin-bottom: 32px;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid var(--border-color);
    border-radius: 4px;

    tr {
      &:first-of-type td {
        border-color: transparent;

        &:first-of-type img {
          margin-top: -3px;
        }
      }

      &:not(:first-of-type) td {
        border-color: var(--border-color);
      }

      th {
        padding: 0;
      }

      td {
        border-top: 1px solid;
        padding: 3px;

        @media (max-width: 699px) {
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }

    a {
      display: inline-block;
      margin-top: 1px;
      margin-right: 2px;
      border: 0;
      height: 22px;

      &:focus {
        box-shadow: 0 0 0 2px var(--focus);
      }
    }

    img {
      margin-top: -4px;
      margin-right: 2px;
      margin-left: 2px;
      width: 16px;
    }

    code {
      position: relative;
      top: -1px;
    }
  }

  pre {
    overflow: auto;
  }

  pre,
  code {
    border-radius: 4px;
    background: var(--bg-primary);
    padding: 3.2px 6.4px;
    font-size: 0.75rem;
  }

  :not(pre) > code {
    color: var(--orange-primary);
  }

  a {
    margin-right: -1px;
    margin-left: -1px;
    border-bottom: 2px solid transparent;
    padding-right: 1px;
    padding-left: 1px;
    color: var(--orange-primary);
    text-decoration: none;
    outline: none;

    &:hover {
      border-color: var(--orange-primary);
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
      border-radius: 1px;
    }
  }

  .react-select-container {
    display: inline-block;
    top: -4px;
    margin-left: 20px;
    width: 220px;
  }
}
