.loader {
  margin: 40px auto;
  text-indent: -9999em;
  width: 12em;
  height: 12em;
  border-radius: 50%;
  background: linear-gradient(
    to right,
    var(--orange-primary) 10%,
    var(--bg-primary) 42%
  );
  position: relative;
  overflow: hidden;
  animation: loading 1.5s infinite linear;
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: var(--orange-primary);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: var(--bg-primary);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
