.invisible-checkbox {
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + .background {
    .toggle {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
}

.background {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  border-radius: 14px;
  width: 60px;
  height: 28px;
  background: var(--orange-primary);
  outline: none;

  &:focus {
    box-shadow: 0 0 0 2px var(--focus);
  }

  .toggle {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 24px;
    width: 24px;
    height: 24px;
    background: var(--bg-primary);
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    transition: 0.2s;
  }
}
