.signup-container {
  font-size: 0.875rem;

  @media (max-width: 399px) {
    font-size: 0.75rem;
  }

  input {
    margin-top: 16px;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--bg-primary);
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
    }
  }

  .terms {
    margin-top: 8px;

    .label-container {
      display: block;
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      font-size: 1.375rem;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        outline: none;
      }

      input:focus + .checkmark {
        box-shadow: 0 0 0 2px var(--focus);
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        width: 18px;
        margin-top: 11px;
        border: 1px solid var(--border-color);
        background-color: var(--bg-secondary);
        border-radius: 2px;

        &:after {
          content: '';
          position: absolute;
          display: none;
          left: 6px;
          top: 1px;
          width: 3px;
          height: 10px;
          border: solid var(--button-text);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      &:hover input ~ .checkmark {
        background-color: var(--bg-primary);
      }

      input:checked ~ .checkmark {
        background-color: var(--orange-primary);
        border: 1px solid var(--orange-primary);

        &:after {
          display: block;
        }
      }
    }

    span {
      font-size: 0.875rem;

      a {
        margin-left: -1px;
        border-bottom: 1px solid var(--text-primary);
        padding-right: 1px;
        padding-left: 1px;
        color: var(--text-primary);
        text-decoration: none;
        outline: none;

        &:focus {
          border-radius: 1px;
          box-shadow: 0 0 0 2px var(--text-primary);
        }
      }
    }

    @media (max-width: 399px) {
      span {
        font-size: 0.75rem;
      }
    }
  }

  .btn-submit {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: var(--button-text);
    background-color: var(--orange-primary);
    font-weight: 600;
    margin-top: 24px;
    border-width: 0px;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px var(--focus);
    }
  }

  .login {
    margin-top: 30px;
    text-align: center;

    a {
      border-bottom: 1px solid transparent;
      padding-right: 1px;
      padding-left: 1px;
      color: var(--orange-primary);
      text-decoration: none;
      outline: none;

      &:hover {
        border-color: var(--orange-primary);
      }

      &:focus {
        border-radius: 1px;
        box-shadow: 0 0 0 2px var(--orange-primary);
      }
    }
  }
}
