.react-select-container.months {
  top: -0.5px;
  margin-right: 16px;
  margin-left: 4px;

  @media (max-width: 639px) {
    display: block;
    margin-top: 10px;
    margin-left: 0;
  }
}
