.profile-container input {
  width: 100%;
  height: 40px;
  margin-bottom: 12px;
  padding: 0 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-primary);
  outline: none;

  &:focus {
    box-shadow: 0 0 0 2px var(--orange-primary);
  }

  &:disabled {
    background-color: var(--disabled);
    cursor: not-allowed;
  }
}
