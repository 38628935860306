.wallet-address {
  button {
    &:nth-of-type(1) {
      color: var(--text-primary);
      background-color: var(--bg-secondary);
      border: 1px solid var(--border-color);
      font-weight: 400;
      outline: none;

      &:focus {
        box-shadow: 0 0 0 2px var(--focus);
      }
    }
  }
}
