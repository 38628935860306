.date-picker {
  font-family: var(--font-primary);
  display: inline-block;
  position: relative;
  margin-right: 16px;
  top: -1px;

  .customInfoPanel {
    padding: 22px 22px 0 22px;

    button {
      margin: 0 8px 8px 0;
      padding: 4px 10px;
      background: var(--bg-secondary);
      color: var(--text-primary);
      border: 1px solid var(--border-color);
      border-radius: 3px;
      font-size: 0.875rem;
      outline: none;

      &:focus {
        box-shadow: 0 0 0 2px var(--focus);
      }
    }

    .selectedButton {
      background: var(--orange-primary);
      color: var(--button-text);
      border: 1px solid var(--orange-primary);
      font-size: 0.867rem;
      font-weight: 500;
    }
  }

  .DateInput {
    border-radius: 4px;
    width: 127px;

    &:first-of-type .DateInput_input {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-of-type .DateInput_input {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  input {
    text-align: center;
  }

  .DateRangePickerInput__withBorder {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--bg-secondary);
  }

  .DateInput_input {
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: var(--wide-spacing);
    height: 38px;
    line-height: 18px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    cursor: pointer;
  }

  .DateInput_input::selection {
    background: var(--border-color);
  }

  .DateInput_input__focused {
    border-bottom-color: var(--orange-primary);
  }

  .DateRangePickerInput_arrow_svg {
    fill: var(--orange-primary);
  }

  .DateInput_fang,
  .DateRangePicker_picker {
    margin-top: -14px;
  }

  .DateRangePicker_picker {
    background: var(--bg-secondary);
  }

  .DayPicker__withBorder {
    border-radius: 4px;
  }

  .DateInput_fangShape {
    fill: var(--bg-secondary);
  }

  .DateInput_fangStroke {
    stroke: var(--bg-secondary);
  }

  .DayPickerNavigation_svg__horizontal {
    fill: var(--orange-primary);
  }

  .DayPickerNavigation_svg__vertical {
    fill: var(--text-primary);
  }

  .DayPickerNavigation_button__default {
    border: 0;
    outline: none;
    background: var(--bg-secondary);

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
    }

    &:active {
      background: var(--bg-secondary);
    }
  }

  .CalendarDay {
    border-color: var(--border-color);

    &:focus {
      outline: 2px solid var(--focus);
    }
  }

  .CalendarDay__default {
    background: var(--bg-secondary);
    color: var(--text-primary);
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span:active,
  .CalendarDay__selected_span {
    border-color: var(--orange-primary);
    background: var(--orange-primary);
    color: var(--button-text);
  }

  .CalendarDay__selected,
  .CalendarDay__selected:hover,
  .CalendarDay__selected:active {
    border-color: var(--orange-secondary);
    background: var(--orange-secondary);
    color: var(--button-text);
  }

  .CalendarDay__selected,
  .CalendarDay__selected_span {
    font-weight: 600;
  }

  .CalendarMonth,
  .CalendarMonthGrid,
  .DayPicker {
    background: var(--bg-secondary);
  }

  .CalendarMonth_caption,
  .DayPicker_weekHeader {
    color: var(--text-primary);
  }
}
