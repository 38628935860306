.logo {
  box-sizing: border-box;

  a {
    display: inline-block;
    margin-top: 2px;
    margin-left: -12px;
    border-radius: 3px;
    padding-right: 12px;
    padding-left: 12px;
    line-height: 1;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
    }

    @media (max-width: 919px) {
      margin-right: -12px;
      margin-left: 0;
    }

    svg {
      fill: var(--text-primary);
    }
  }
}
