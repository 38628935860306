.button-profile {
  height: 40px;
  width: 120px;
  border: none;
  border-radius: 20px;
  margin-bottom: 6px;
  margin-left: 10px;
  padding: 0 30px;
  background-color: var(--orange-primary);
  color: var(--button-text);
  text-align: center;
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 2px var(--focus);
  }
}
