footer {
  position: relative;
  height: 60px;
  text-align: center;

  &.floating {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .copyright {
    position: absolute;
    width: 100%;
    color: var(--text-secondary);
    font-size: 0.875rem;
    font-family: var(--font-primary);
    bottom: 0;

    .fineprint {
      font-size: 0.6875rem;
    }

    @media (max-width: 414px) {
      font-size: 0.75rem;
    }
  }
}
