:root {
  --font-primary: 'Montserrat', sans-serif;
  --font-secondary: 'Roboto', sans-serif;
  --wide-spacing: 0.25px;
  --orange-primary: #ff9921;
  --orange-secondary: #ff7000;
  --blue-primary: #ff7f03;
  --blue-secondary: #fff9e7;
  --bg-primary: #fbfaff;
  --bg-secondary: #ffffff;
  --disabled: #fff9e7;
  --border-color: #f1e0d2;
  --text-primary: #000000;
  --text-secondary: #00000080;
  --focus: #000000;
  --button-text: #ffffff;
  --shadow: rgba(0, 0, 0, 0.4);
  --invert-color: none;
  --code-option: #f375a8;
  --code-value: #6f4efa;
}

.dark {
  --orange-secondary: #ff9921;
  --blue-secondary: #1f1d26;
  --bg-primary: #000000;
  --bg-secondary: #111014;
  --disabled: #39352e;
  --border-color: #372d28;
  --text-primary: #ffffff;
  --text-secondary: #999999;
  --focus: #ffffff;
  --invert-color: grayscale(1) invert(1);
  --code-value: #ffce39;
}
