.reset-password-container {
  font-size: 0.875rem;

  @media (max-width: 399px) {
    font-size: 0.75rem;
  }

  input {
    margin-top: 16px;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--bg-primary);
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
    }
  }

  .btn-submit {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: var(--button-text);
    background-color: var(--orange-primary);
    font-weight: 600;
    margin-top: 16px;
    border-width: 0px;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px var(--focus);
    }
  }

  .remembered-password {
    margin-top: 30px;
    text-align: center;

    a {
      border-bottom: 1px solid transparent;
      padding-right: 1px;
      padding-left: 1px;
      color: var(--orange-primary);
      text-decoration: none;
      outline: none;

      &:hover {
        border-color: var(--orange-primary);
      }

      &:focus {
        border-radius: 1px;
        box-shadow: 0 0 0 2px var(--orange-primary);
      }
    }
  }
}
