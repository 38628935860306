.worker-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;
  font-family: var(--font-primary);

  .worker-tools {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
  }
}
