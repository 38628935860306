.search {
  margin-top: -1px;
  margin-bottom: 20px;
  font: 0.875rem var(--font-primary);

  label {
    display: none;
  }

  input {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    padding-right: 10px;
    padding-left: 10px;
    width: 640px;
    height: 40px;
    letter-spacing: var(--wide-spacing);
    outline: none;
    transition: width 0.25s;

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
    }

    @media (max-width: 919px) {
      width: 100%;
    }
  }

  button {
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    background-color: var(--orange-primary);
    color: var(--button-text);
    width: 140px;
    height: 40px;
    letter-spacing: var(--wide-spacing);
    font-weight: bold;
    outline: none;
    transition: margin 0.25s;

    &:focus {
      box-shadow: 0 0 0 2px var(--focus);
    }

    @media (max-width: 919px) {
      margin-top: 10px;
      margin-left: 0;
      width: 180px;
    }
  }
}
