.infobox {
  margin-bottom: 18px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  padding: 12px 24px;
  font-size: 0.875rem;

  p {
    margin-bottom: 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  pre {
    white-space: normal;
  }

  pre,
  :not(pre) > code {
    border-radius: 4px;
    background: var(--bg-primary);
    padding: 3.2px 6.4px;
    font-size: 0.8125rem;
  }

  :not(pre) > code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .option {
    color: var(--code-option);
  }

  .value {
    color: var(--code-value);
  }

  a {
    margin-right: -1px;
    margin-left: -1px;
    border-bottom: 2px solid transparent;
    padding-right: 1px;
    padding-left: 1px;
    color: var(--orange-primary);
    font-weight: bold;
    text-decoration: none;
    outline: none;

    &:hover {
      border-color: var(--orange-primary);
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
      border-radius: 1px;
    }
  }
}
