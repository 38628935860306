.logo-title {
  margin: 10px 0 10px -10px;

  a {
    display: block;
    margin: 0 auto;
    padding-left: 10px;
    width: 240px;
    height: 45px;
    border-radius: 2px;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
    }

    @media (min-width: 400px) and (max-width: 699px) {
      margin-left: 0;
    }

    svg {
      fill: var(--text-primary);
    }
  }
}
