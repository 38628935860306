.miner-container {
  display: flex;
  justify-content: center;

  .miner {
    flex: 1;
    max-width: 1024px;
    margin: 32px 16px 16px;
    padding: 16px 32px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--bg-secondary);
    font-family: var(--font-primary);
  }
}
