.metrics {
  background-color: var(--blue-secondary);
  font-family: var(--font-primary);
  letter-spacing: var(--wide-spacing);
  display: flex;
  margin-top: 18px;
  font-size: 0.75rem;
  font-weight: bold;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  .section {
    display: inline-block;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: var(--border-color);
    width: 25%;
    padding: 16px 0;
    text-align: center;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-right-width: 1px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media (max-width: 767px) {
      border-bottom-width: 0;
      width: 50%;

      &:first-child {
        border-bottom-left-radius: 0;
      }

      &:nth-child(even) {
        border-right-width: 1px;
      }

      &:nth-child(2) {
        border-top-right-radius: 4px;
      }

      &:nth-last-child(2) {
        border-bottom-width: 1px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-bottom-width: 1px;
        border-top-right-radius: 0;
      }
    }

    .value {
      color: var(--blue-primary);
      margin-bottom: -4px;
      font-size: 1rem;
    }
  }
}
