.balance {
  float: right;
  font-family: var(--font-primary);

  @media (max-width: 999px) {
    float: none;
    margin-top: 20px;
  }

  .title {
    display: inline;
    position: relative;
    top: -3px;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: var(--wide-spacing);

    &.short {
      display: none;
    }

    @media (max-width: 1279px) {
      display: none;

      &.short {
        display: inline;
      }
    }

    @media (max-width: 999px) {
      top: -5px;

      &.short {
        display: block;
      }
    }
  }

  .value {
    display: inline-block;
    margin-left: 10px;
    width: 180px;
    height: 40px;
    border-radius: 4px;
    padding-top: 7px;
    text-align: center;
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: var(--wide-spacing);
    color: var(--blue-primary);
    border: 1px solid var(--border-color);
    background-color: var(--bg-secondary);

    @media (max-width: 999px) {
      margin-left: 0;
    }
  }
}
