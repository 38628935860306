.toolbar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  height: 60px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-secondary);
  padding: 0 40px;
  z-index: 3;
  box-shadow: 0 4px 4px -4px var(--shadow);
  transition: padding 0.25s;

  nav {
    height: 100%;
  }

  .logo-toolbar {
    height: 40%;
    margin-right: 20px;
    transition: margin 0.25s;
  }

  @media (max-width: 1023px) {
    padding-right: 28px;
    padding-left: 28px;
  }

  @media (max-width: 919px) {
    padding-right: 4px;
    padding-left: 4px;

    .logo-toolbar {
      margin-right: 0;
    }

    .desktop-only {
      display: none;
    }
  }
}
