.dashboard-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;
  font-family: var(--font-primary);

  .admin-tools {
    margin-bottom: 20px;
  }
}
