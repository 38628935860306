.drawer-toggle {
  margin-top: 2px;
  margin-left: -15px;
  border-radius: 3px;
  padding: 17px 15px;
  width: 60px;
  height: 94%;
  cursor: pointer;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 2px var(--orange-primary);
  }

  @media (min-width: 920px) {
    display: none;
  }

  div {
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 1px;
    height: 2px;
    background-color: var(--text-primary);
  }
}
